* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
body {
	padding-top: constant(safe-area-inset-top); /* iOS 11.0+ */
  	padding-top: env(safe-area-inset-top); /* iOS 11.2+ */
	font-family: "Lato", sans-serif;
}

h1,
h2,
h3 {
	color: rgb(54, 54, 54);
}

h3,
h4 {
	font-weight: 400;
	color: rgb(100, 100, 100);
}

/* iPhone X 이상 모델의 상단바 배경 색상 변경 */
@media only screen and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) {
  body {
    background-color: white;
  }
}